import { useInputControl } from "@conform-to/react";
import { Check, ChevronsUpDown } from "lucide-react";
import React, { useEffect, useId, useRef } from "react";

import { Button } from "#app/components/ui/button.tsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "#app/components/ui/command.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "#app/components/ui/popover.tsx";
import { cn } from "#app/utils/misc.tsx";

import { Checkbox, type CheckboxProps } from "./ui/checkbox.tsx";
import { Input } from "./ui/input.tsx";
import { Label } from "./ui/label.tsx";
import { Textarea } from "./ui/textarea.tsx";

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors;
  id?: string;
}) {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map((e) => (
        <li key={e} className="text-xs text-red-500">
          {e}
        </li>
      ))}
    </ul>
  );
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
  inputType,
  placeHolder,
  isDisabled,
  capitalization,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
  inputType?: string;
  placeHolder?: string;
  isDisabled?: boolean;
  capitalization?: string;
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
        type={inputType ?? "text"}
        placeholder={placeHolder ?? ""}
        readOnly={isDisabled}
        className={`${
          isDisabled ? "cursor-not-allowed text-muted-foreground" : ""
        } ${capitalization}`}
      />
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  textareaProps: React.InputHTMLAttributes<HTMLTextAreaElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
        className="min-h-[200px]"
      />
      <div className="min-h-[32px] px-4 pb-3 pt-1">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
  showErrors,
}: {
  labelProps: JSX.IntrinsicElements["label"];
  buttonProps: CheckboxProps & {
    name: string;
    form: string;
    value?: string;
  };
  errors?: ListOfErrors;
  className?: string;
  showErrors?: boolean;
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps;
  const fallbackId = useId();
  const checkedValue = buttonProps.value ?? "on";
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  });
  const id = buttonProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;
  return (
    <div className={className}>
      <div className="flex gap-2">
        <Checkbox
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...checkboxProps}
          checked={input.value === checkedValue}
          onCheckedChange={(state) => {
            input.change(state.valueOf() ? checkedValue : "");
            buttonProps.onCheckedChange?.(state);
          }}
          onFocus={(event) => {
            input.focus();
            buttonProps.onFocus?.(event);
          }}
          onBlur={(event) => {
            input.blur();
            buttonProps.onBlur?.(event);
          }}
          type="button"
          className="ring-1 ring-inset ring-primary"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="self-center text-body-xs"
        />
      </div>
      {showErrors && (
        <div className="px-4 pb-3 pt-1">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      )}
    </div>
  );
}

export function Combobox({
  labelProps,
  inputProps,
  errors,
  className,
  items,
  defaultData,
  handleStateChange,
  inputName,
  showErrors,
  isDisabled,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
  items: Array<{ label: string; value: string }>;
  defaultData?: string;
  handleStateChange?: (value: string, name: string) => void;
  inputName?: string;
  showErrors?: boolean;
  isDisabled?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultData ?? "");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValue(defaultData ?? "");
  }, [defaultData]);

  const fallbackId = useId();
  const id = inputProps.id ?? inputProps.name ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  return (
    <div
      ref={containerRef}
      className={`flex flex-col space-y-1 pt-1.5 ${className}`}
    >
      <Label htmlFor={id} {...labelProps} />
      <Input
        {...inputProps}
        defaultValue={undefined}
        value={value}
        onChange={() => {}}
        className="hidden"
      />
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={`justify-between ${
              value ? "" : "text-muted-foreground"
            }`}
            disabled={isDisabled}
          >
            {value
              ? items.find((item: any) => item.value === value)?.label
              : "Selecione..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          style={{
            width: containerRef.current?.offsetWidth,
          }}
          container={containerRef.current}
          className="max-h-64 w-[200px] overflow-y-auto p-0"
        >
          <Command>
            <CommandInput placeholder={`Procurar ${inputName}...`} />
            {/* <ScrollArea className="h-96"> */}
            <CommandEmpty>Não encontrado.</CommandEmpty>
            <CommandGroup>
              {items.map((item: any) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                    handleStateChange?.(currentValue, inputName ?? "");
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
            {/* </ScrollArea> */}
          </Command>
        </PopoverContent>
      </Popover>
      {showErrors && (
        <div className="min-h-[32px] px-4 pb-3">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      )}
    </div>
  );
}

export function Dropdown({
  labelProps,
  inputProps,
  errors,
  className,
  items,
  defaultData,
  isDisabled,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
  items: Array<{ label: string; value: string }>;
  defaultData: string | number;
  isDisabled?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultData);

  const fallbackId = useId();
  const id = inputProps.id ?? inputProps.name ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  return (
    <div className={`flex flex-col space-y-1 pt-1.5 ${className}`}>
      <Label htmlFor={id} {...labelProps} />
      <Input
        {...inputProps}
        defaultValue={undefined}
        value={value}
        onChange={() => {}}
        className="hidden"
      />
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            disabled={isDisabled}
            aria-expanded={open}
            className={`justify-between ${
              value ? "" : "text-muted-foreground"
            }`}
          >
            {value
              ? items.find((item: any) => item.value === value)?.label
              : "Selecione..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="max-h-64 w-[200px] overflow-y-auto p-0">
          <Command>
            <CommandGroup>
              {items.map((item: any) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      <div className="px-4 pb-3">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}
